<template>
	<v-row align="center" justify="center" class="fill-height">
		<v-col cols="9" sm="3" md="3" lg="3">
			<v-card
				:disabled="true"
				height="250"
				:class="['mx-5', 'custom-card-wr']"
				align="center"
				justify="center"
			>
				<v-card-text class="pt-7">
					<v-img
						:src="require(`@/assets/images/microscope.png`)"
						width="90"
					></v-img>
				</v-card-text>
				<v-card-text class="title font-weight-black black--text px-10">
					{{ $t("app.ko.analyzing_scientific_phenomenon.title") }}
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "AnalyzingPhenomenonPage",

	created() {
		this.$eventBus.$on("ko-redirect-back", (payload) => {
			this.$router.push({
				name: "KnowledgeOrganizers",
			});
		});
	},

	beforeDestroy() {
		this.$eventBus.$off("ko-redirect-back");
	},
};
</script>
